import './ListItem.css'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import ProductItem from './ProductItem'
export default function ListPro(){
    const arr = [
        'dfght',
        'dfght',
        'dfght',
        'dfght',
        'dfght',
        'dfght'
    ]
    // const [info, setInfo] = useState([]);
    // useEffect(() => {
    //   getAllProducts(setInfo) 
    // }, [])
    // console.log(info)
    return(
        <div className='basket'>
             <Navbar/> 
             <div className="group-baskets">       
                {
                   arr.map((el,index)=>{
                        return <ProductItem key={index} nameItem={el}/>
                    })
                }
            </div>

        <Footer/>
        </div>
       
    )
}