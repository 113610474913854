import { useHistory } from 'react-router';
import './ListItem.css'
export default function ItemProduct({nameItem,priceOb,magnumName,magnumId,weightItem,descItem,idProduct}){
    const history = useHistory()
    const handleEnter=(e)=>{
        e.preventDefault();
        history.push(`/view/product/${idProduct}`)
    }
    return(
        <div className='itemProductTemp' onClick={(e)=>(handleEnter(e))}>
            <div className="item_product row align-items-center justify-content-between">
                {/* <div className="col-3">
                    <img src={imageItem} alt="image" className='image-item'/>
                </div> */}
                <div className="col-11 list-texts">
                    <div className='name-item'>
                    <span>{nameItem}</span>
            <div>{magnumId+" - "+magnumName+" - "} <strong><span>{priceOb} &#8376;</span></strong></div>
                    </div>
                   <div  className='we-desc-item'>
                        <span className='weight-item'>{weightItem} грамм</span>
                        <span className='desc-item'>{descItem}</span>
                   </div>
                </div>
                <div className="col-1">
                   <div className="icon">
                   <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M1 17L9 9L1 1" 
                            stroke="#3F3C3C" 
                            strokeWidth="1" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        />
                    </svg>
                   </div>
                </div>
            </div>
        </div>
    )
}