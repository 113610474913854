import './Item.css'
import {useEffect,useState} from 'react'
export default function Item({name,weight,unit,colorHex}){
    const [colorBack,setColorBack] = useState('#ffffff')
    useEffect(() => {    
        if(colorHex!=='#6B9A51'){
            setColorBack('rgba(229, 151, 51, 0.05)')
        }
        else{
            setColorBack('rgba(107, 154, 81, 0.05)')
        }
    }, [colorHex,colorBack])
    return(
        <div className="item" style={{background:colorBack,borderColor:colorHex}}>
            <div className="howmany"><span style={{color:colorHex}}>{weight+" "+unit}</span></div>
            <div className='itemText'><span>{name}</span></div>
        </div>
    )
}