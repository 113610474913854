import Inside from "./Inside"
import './Footer.css'
export default function Footer(){
    const arr = [
        'SymptoMaster',
        'ClouDoc',
        'Avocado'
    ]
    const arr2 = [
        'Проверьте свои симптомы и не упустите серьезных осложнений',
        'Научитесь управлять своим здоровьем',
        'Блюда и рецепты блюд из авокадо'
    ]
    const arr_inf = [
        'https://www.symptomaster.com/client/',
        'https://cloudoc.kz/',
        'https://nomadiet.kz/'
    ]
    return(
        <div className="footer">
            <div className="group-links">
                {
                    arr.map((el,index)=>(
                        <Inside heading={arr[index]} text = {arr2[index]} key = {index} info={arr_inf[index]}/>
                    ))
                }
            </div>
          
         <div className="group-link">
           <div className="row align-items-center justify-content-center">
               <div className="col-12 col-sm-3">
                    <div className="footerTexts">
                        <span className='logoName'>NutriSteppe</span>
                        <br/>
                        <span className='logoDesc'>ВАШ СПУТНИК ЗДОРОВЬЯ</span>
                    </div>
               </div>
           </div>
         </div>
        </div>
    )
}