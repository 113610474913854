import DropDown from "./DropDown";
import Navbar from "../navbar/Navbar";
import {useState,useEffect} from 'react'
import {getAllBaskets2,getBasketById} from '../../api'
import './ListItem.css'
import SetCompare from './SetCompare'
import Preloader from '../preloader/Preloader'
import PreloaderMini from "../preloader/PreloaderMini";
export default function Compare(){
    const[basket, setBasket] = useState()
    const[info1,setInfo1] = useState()
    const[info2,setInfo2] = useState()
    const[selected,setSelected] = useState({
        basket_1:'1',
        basket_2:'1'
    })
    useEffect(()=>{
        getAllBaskets2(setBasket)
    },[])
    useEffect(() => {
        setInfo1()
        getBasketById(selected.basket_1,setInfo1)
      }, [selected.basket_1])
      useEffect(() => {
        setInfo2()
        getBasketById(selected.basket_2,setInfo2)
      }, [selected.basket_2])
 
    return(
        <div className="compare">
            <Navbar/>
            {
                basket?
               <div>
                    <DropDown cb={setSelected} basket={basket?basket.data:{}}/>
                    <div className="selected_values">
                        <div className="basket_1">
                           {
                               info1?
                               <SetCompare info={info1} selectedOb={selected.basket_1}/>
                               :<PreloaderMini/>
                           }
                        </div>
                        <div className="basket_2">
                        {
                               info2?
                               <SetCompare info={info2} selectedOb={selected.basket_2}/>
                               :<PreloaderMini/>
                           }
                        </div>
                    </div>
               </div>
                :<Preloader/>
            }
        </div>
    )
}