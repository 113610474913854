import { useHistory } from 'react-router';
import './ListItem.css'
export default function BasketItem({nameItem,id,desc}){
    const history = useHistory()
    const handleEnter=(e)=>{
        e.preventDefault();
        history.push(`/view/basket/${id}`)
    }
    // const arr1 = created.split(' ');
    // const arr2 = updated.split(' ');
    return(
        <div className='item-product basket-item' onClick={(e)=>(handleEnter(e))}>
            <div className="row align-items-center">
                <div className="col-11 list-texts">
                    <div className='name-item'>
                    <span>{nameItem}</span>
                    </div>
                    <span className='desc'>{desc}</span>
                </div>
                <div className="col-1">
                   <div className="icon">
                   <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M1 17L9 9L1 1" 
                            stroke="#3F3C3C" 
                            strokeWidth="1" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        />
                    </svg>
                   </div>
                </div>
            </div>
        </div>
    )
}