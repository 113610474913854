import menu from '../../images/menu.svg'
import back from '../../images/back.svg'
import './Navbar.css'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
export default function Navbar(){
  const{goBack} = useHistory()
  const[click,setClick] = useState(false)
  const history = useHistory()
  const handleQR=(e)=>{
      e.stopPropagation();
      history.push('/scanner');
  }
  const handleBasket=(e)=>{
    e.stopPropagation();
    history.push('/view/baskets');
}
const handleProduct=(e)=>{
    e.stopPropagation();
    history.push('/view/product/1');
}
    return(
         <div>
              <div className="row align-items-center justify-content-between navbar">
                <div className="col-2 col-sm-1">
                    <img src={back} alt="back" className='back' onClick={goBack}/>                
                </div>
                <div className="col-8 col-sm-10">
                <div className='powered'>
                        <span>Nutristeppe</span>
                </div>
                </div>
                <div className="col-2 col-sm-1">
                    <img src={menu} alt="menu" className='menu' onClick={(e)=>{
                             e.stopPropagation()
                             setClick(!click)
                        }} />
                </div>
          </div>
           <div className={click===true|| click==='true' ? "row modal justify-content-end align-items-baseline":'none'} onClick={()=>{setClick(false)}}>
           <div className="col-7">
               <div className="modal-content">
                 <div className="texts-nav">
                   <span onClick={(e)=>{handleQR(e)}}>NutriSteppe QR</span>
                   <span onClick={(e)=>{handleBasket(e)}}>NutriSteppe Basket</span>
                   <span onClick={(e)=>{handleProduct(e)}}>NutriSteppe Product</span>
                 </div>
                   <div className="hr-ver-nav">
                       <div className="hr"></div>
                       <div className="ball"></div>
                       <div className="ball"></div>
                       <div className="ball"></div>
                   </div>
               </div>
           </div>
        </div>
         </div>
    )
}