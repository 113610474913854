import './Footer.css'
export default function Inside({heading,text,info}){
    return(
       <div className="shape">
            <div className="inside">
                <div className="textLinks">
                  <a href={info} className="link" target="_blank" rel="noreferrer"><span className="pSpan">{heading}</span></a>
                    <br/>
                     <a href={info} className="link" target="_blank" rel="noreferrer"><span className='spanP'>{text}</span></a>
                </div>
                <div className="linkTogo">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        d="M1 17L9 9L1 1" 
                        stroke="white" 
                        strokeWidth="1" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </svg>
                </div>
        </div>
       </div>
    )
}