import {useState,useEffect} from 'react'
import './ListItem.css'
export default function DropDown({basket,cb=Function.prototype}){
    const[select,setSelect] = useState({
        basket_1:'1',
        basket_2:'1'
    })
    useEffect(() => {
        cb(select)
    }, [select,cb])
    const changeField = (e)=>{
        e.preventDefault();
        setSelect({...select,[e.target.name]:e.target.value})
      } 
    return(
        <div className="drop__down">
            <form action="" className="drop__down__form">
           <div className="choose">
           <label htmlFor="basket_1">BASKET 1</label>
                <select name="basket_1" id="basket_1" className='browser-default' onChange={(e)=>{changeField(e)}}>
                    {
                        basket ? basket.map((el,index)=>{
                        return <option 
                            value={el.id} 
                            key={el.id}
                            >{el.name}</option>
                        }):null
                    }   
                </select>
           </div>
               <div className="choose">
               <label htmlFor="basket_2">BASKET 2</label>
               <select name="basket_2" id="basket_2" className='browser-default' onChange={(e)=>{changeField(e)}}>
                {
                       basket? basket.map((el,index)=>{
                        return <option 
                            value={el.id} 
                            key={el.id}
                            >{el.name}</option>
                        }):null
                    }
                </select>
               </div>
            </form>
        </div>
    )
}