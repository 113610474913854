import BasketItem from './BasketItem'
import './ListItem.css'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import {useEffect,useState} from 'react'
import getAllBaskets from '../../api'
import Preloader from '../preloader/Preloader'
export default function GroupsSet(){
    const [basket, setBasket] = useState([])
    useEffect(()=>{
        getAllBaskets(setBasket)
    },[])
    return(
       <div>
           {
        basket ?<div className='basket'>
                    <Navbar/> 
                    <div className="basket-heading"><span>Корзины</span></div>
                        <div className="group-baskets">       
                            {
                           basket.data ?  basket.data.map((el,index)=>{
                            return <BasketItem key={basket.data[index].id} nameItem={basket.data[index].name} desc={basket.data[index].description} created={basket.data[index].created_at} updated={basket.data[index].updated_at} id={basket.data[index].id}/>
                        }):<Preloader/>
                            }
                        </div>
                    <Footer/>
                </div>
                :<Preloader/>
           }
       </div>
       
    )
}