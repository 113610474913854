import './Molecular.css'
export default function Molecular(){
   return(
       <div className="molecular">
           <div className="main">
               <span className="heading">
               Молекулярная Анатомия Авокадо
               </span>
               <br/>
               <span className='for100'>на 100 г продукта</span>
           </div>
       </div>
   )
}