import Result from './layers/Result';
import Scanner from './layers/Scanner';
import React from 'react'
import {BrowserRouter as Router, Route , Switch} from 'react-router-dom'
import Set from './layers/Set';
import MainPage from './layers/main/MainPage';
import GroupsSet from './layers/set/GroupsSet';
import ListPro from './layers/set/ListPro';
import QRS from './layers/QRS';
import Compare from './layers/set/Compare';
function App() {
  return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* <Route path='/' exact component={MainPage}/> */}
            <Route path='/view/product/:id' component={Result}/>
            <Route path='/view/basket/:id' component={Set}/>
            <Route path='/view/products' component={ListPro}/>
            <Route path='/scanner' component={Scanner}/>
            <Route path='/'exact component={GroupsSet}/>
            <Route path='/view/qrcodes' component={QRS}/>
            <Route path='/compare' component={Compare}/>
          </Switch>
        </Router>
      </React.Fragment>
  );
}

export default App;