import IndexSetCompare from '../index/IndexSetCompare'
import PreloaderMini from '../preloader/PreloaderMini';
function SetCompare({info,selectedOb}) { 
  // const[info,setInfo] = useState()
  // useEffect(() => {
  //   setInfo()
  //   getBasketById(id,setInfo)
  // }, [selectedOb])
  return (
    <div>
        {
        info && selectedOb? 
            <div className='result'>
                    <IndexSetCompare
                        name={info?info.data.basket_name:''}
                        products={info? info.data.products : ''}
                        ingredients={info? info.data.ingredients:[]}
                        lights={info?info.data.lights:[]}
                        weight={info? info.data.statistics.total_weight:null}
                        price={info? info.data.statistics.total_price:null}
                      /> 
              </div> 
        :<PreloaderMini/>
      }
    </div>
  );
}
export default SetCompare;