import Preloader from '../preloader/Preloader'
import ItemProduct from './ItemProduct'
import './ListItem.css'
export default function GroupItems({ing}){
    console.log(ing)
    return(
        <div className="group-items">       
                {
                    ing? ing.map((el,index)=>{
                        return <ItemProduct 
                            key={index} 
                            weightItem={ing[index].weight} 
                            nameItem={ing[index].name} 
                            descItem={''} 
                            idProduct ={ing[index].id}
                            magnumName={ing[index].magnum_name}
                            magnumId={ing[index].magnum_id}
                            priceOb={ing[index].product_price}
                            />
                    }):<Preloader/>
                }
        </div>
    )
}